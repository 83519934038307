.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.footerContainerV2 {
    font-family: var(--font-primary);
    padding-top: clamp(25px, 3.12vw, 60px);
    padding-left: 100px;
    padding-right: 100px;
}

.allHeadingsV2 {
    font-size: clamp(14px, 1.3vw, 25px);
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: -0.14px;
    text-align: left;
    margin: 25px 0 13px;
}

.line {
    margin: 0 0 10px;
    height: 1px;
    flex-grow: 0;
}

.lineMobile {
    display: none;
}

.linksCol {
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.11px;
    margin-bottom: 5px;
    max-width:100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    overflow: hidden;
}

.linksCol a {
    text-decoration: none;
}

.headingMobile {
    display: none;
}

.socialFooterContainer {
    display: none;
    flex-direction: row;
    margin-top: 15px;
}

.socialImageContainer {
    width: clamp(36px, 2.6vw, 50px);
    height: clamp(36px, 2.6vw, 50px);
    position: relative;
    margin-right: 10px;
}

.membersDataContainer {
    margin: 25px 0 clamp(20px, 1.56vw, 30px);
}

.membersDataHeading {
    font-size: clamp(18px, 1.3vw, 25px);
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-top: 50px;
    margin-bottom: 2px;
    text-transform: uppercase;
}

.paymentContainer {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
}

.paymentContainerDesktop {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    /* width: 100%; */
}

.paymentContainerMobile {
    display: none;
}

.colMobile {
    display: none;
}

.disclaimerContainer {
    margin-top: clamp(30px, 2.86vw, 55px);
}

.disclaimerHeading {
    font-size: clamp(18px, 1.3vw, 25px);
    font-weight: bold;
    letter-spacing: -0.13px;
    margin-top: 30px;
    margin-bottom: 2px;
    text-transform: uppercase;
}

.disclaimerText {
    opacity: 0.6;
    font-weight: 300;
    font-size: clamp(12px, 0.93vw, 18px);
    letter-spacing: -0.22px;
}

.memberContainer {
    display: flex;
    align-items: center;
}

.memberContainer > div {
    margin-right: 20px;
}

.copyright {
    text-align: center;
    padding: 15px 0;
    opacity: 0.6;
    font-size: 12px;
    letter-spacing: -0.08px;
}

.extraSpace {
    height: 68px;
}

.memberImageContainer {
    position: relative;
    height: 55px;
    width: 80px;
}

@media screen and (max-width: 767px) {

    .footerContainerV2 {
        padding-left: 0;
        padding-right: 0;
        margin-top: -1%;
    }

    .footerTop {
        text-align: center;
    }

    .footerTopRight {
        margin-top: 0;
    }

    .headingMobile {
        display: block;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.22px;
        margin-bottom: 2px;
        text-transform: uppercase;
    }

    .extraSpace {
        display: none;
    }



    .lineMobile {
        display: flex;
        margin: 0 5% 2%;
        height: 1px;
    }

    .line {
        margin: 0 5% 2%;
        height: 1px;
        flex-grow: 0;
    }

    .winzoFooterImg {
        display: none;
    }

    .socialFooterContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 24px;
    }

    .socialFooterContainer > a {
        margin-right: 0;
    }

    .membersDataHeading {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .memberContainer {
        justify-content: center;
        margin-top: 5%;
    }

    .ourPartnerContainer {
        margin-top: 5%;
    }

    .colMobile {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        text-align: left;
        flex-direction: column;
    }

    .allHeadingsV2, .disclaimerHeading {
        font-size: clamp(16px, 1.3vw, 25px);
        font-weight: bold;
        text-align: center;
        margin: 2% 0px 6px;
    }

    .linksCol {
        opacity: 0.8;
        padding: 5px;
        padding-right: 5px;
        font-weight: 500;
    }

    .paymentContainer {
        justify-content: center;
        margin-left: 3.5%;
        margin-top: 5%;
    }

    .paymentContainerDesktop {
        display: none;
    }

    .paymentContainerMobile {
        display: flex;
        position: relative;
        justify-content: space-around;
        height: 22px;
    }

    .disclaimerText {
        margin: 0 5%;
    }

    .copyright {
        font-size: 12px;
        padding: 15px;
    }
}