.allHeadingsV2 {
    font-size: clamp(18px, 1.3vw, 25px);
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: -0.14px;
    text-align: left;
    margin: 50px 0 2px;
}

.linksCol {
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.11px;
    margin-bottom: 10px;
    opacity: 0.7;
    
}

.linksCol a {
    text-decoration: none;
}

.winzoFooterImg {
    width: 276px;
    height: 68px;
    position: relative;
}

.socialFooterContainer {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

.socialImageContainer {
    width: clamp(36px, 2.6vw, 50px);
    height: clamp(36px, 2.6vw, 50px);
    position: relative;
    margin-right: 10px;
}

.line {
    margin: 0 0 10px;
    height: 1px;
    flex-grow: 0;
    background-color: rgba(255, 255, 255, 0.2);
}

.gamesCategory {
    font-size: 13px;
    opacity: 0.8;
    margin: auto 0 auto 3%;
    font-weight: bold;
}

.gamesCategoryIcon {
    bottom: -18% !important;
}

@media screen and (max-width: 767px) {
    .footerTopLeftV2 {
        display: none;
    }

    .linksCol {
        padding-right: 5px;
        font-weight: 500;
    }

}